import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const AWSSupport = {};

AWSSupport.Get = async (case_id) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getAWSSupport, { case_id : case_id }));
		const item = ref.data.getAWSSupport;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

AWSSupport.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanAWSSupport));
		let items = ref.data.scanAWSSupport.items;
		while (ref.data.scanAWSSupport.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanAWSSupport, { nextToken: ref.data.scanAWSSupport.nextToken }));
			items = items.concat(ref.data.scanAWSSupport.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

AWSSupport.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createAWSSupport, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

AWSSupport.CommentGetAll = async (case_id) => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryAWSSupportComment, { case_id }));
		let items = ref.data.queryAWSSupportComment.items;
		while (ref.data.queryAWSSupportComment.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryAWSSupportComment, { case_id, nextToken: ref.data.queryAWSSupportComment.nextToken }));
			items = items.concat(ref.data.queryAWSSupportComment.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

AWSSupport.CommentCreate = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createAWSSupportComment, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default AWSSupport;
