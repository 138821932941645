import {
	Navbar,
	Nav,
	NavDropdown,
} from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';

const Default = () => {

	const history = useHistory();

	const handleClick = (uri) => {
		if(uri && uri.match(/^http/) !== null){
			//window.location.href = uri;
			window.open(uri, '_blank');
		}
		else if(uri){
			history.push(uri);
		}
	};
	return (
		<div>
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="no_print">
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav onSelect={handleClick} className="mr-auto">
						<NavDropdown title="顧客管理" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/customer/"}>顧客管理</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/portal-user/"}>ポータルサイトユーザ</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link eventKey={"/domain/"}>ドメイン名</Nav.Link>
						<Nav.Link eventKey={"/ssl/"}>SSL証明書</Nav.Link>
						<Nav.Link eventKey={"/aws/"}>AWSクラウド</Nav.Link>
						<Nav.Link eventKey={"/server/"}>サーバー</Nav.Link>
						<NavDropdown title="メール" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/mail/"}>メール</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/maillog/"}>ログ</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link eventKey={"/ns53/"}>NS53</Nav.Link>
						<NavDropdown title="課金管理" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/bill/"}>月額課金</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/bill-once/"}>都度課金</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/bill-memo/"}>課金メモ</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="見積書" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/quotation/"}>見積書</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/order/"}>発注履歴</NavDropdown.Item>
						</NavDropdown>

						<Nav.Link eventKey={"/invoice/"}>請求書</Nav.Link>
						<NavDropdown title="接続" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/connect/server/"}>サーバー</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/connect/aws/"}>AWS</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="君シリーズ" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"https://backoffice.onocomm.jp/rireki/login.cgi"}>履歴君</NavDropdown.Item>
							<NavDropdown.Item eventKey={"https://backoffice.onocomm.jp/seisan/login.cgi"}>精算君</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link eventKey={"/support/"}>サポート</Nav.Link>
					</Nav>
					<Nav onSelect={handleClick}>
						<NavDropdown title="ログイン情報" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/account/"}>アカウント情報</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item eventKey={"/sign-out/"}>ログオフ</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link eventKey={"/"} style={{color:'white'}}>ver.20240911</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

//ver.20240507 AWS詳細画面にドバンスドOn-Rampプランボタンを追加
//ver.20240606 メール送受信ログの検索機能追加
//ver.20240612 AWSサポート問い合わせ機能の追加
//ver.20240902 NS53のモーダルをcenterdに変更
//ver.20240911 メールのアラート機能を追加

export default withRouter(Default);

