import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { Storage } from 'aws-amplify';
import { covertTime } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, withRouter } from 'react-router-dom';
import { ModalAlert, ModalCustomer } from '../../Objects/Modal';
import { v4 as uuidv4 } from 'uuid';

const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState([]);
	const [customerData, setCustomerData] = useState([]);
	const [defaultPortalUser, setDefaultPortalUser] = useState([]);
	const [portalUser, setPortalUser] = useState([]);
	const [showCustomer, setShowCustomer] = useState([false]);
	const [defaultAWSData, setDefaultAWSData] = useState([]);
	const [awsData, setAWSData] = useState([]);
	const [techFlag, setTechFlag] = useState(true);
	const [case_id, setCaseId] = useState('');

	useEffect(() => {
	
		const getData = async () => {
		
			const resultCustomer = await API.Customer.GetAll();
			const itemsCustomer = resultCustomer.items.filter(x=>x.status !== '無効');

			const resultAWS = await API.AWS.GetAll();
			const itemsAWS = resultAWS.items.filter(x=>x.status !== '無効');

			const resultPortalUser = await API.PortalUser.GetAll();
			const itemsPortalUser = resultPortalUser.items.filter(x=>x.status !== '無効');

			const newData = {
				type: 'basic',
				user_name: localStorage.getItem('support_user_name') ?? '',
			};

			//表示開始
			setData(newData);
			setCustomerData(itemsCustomer);
			setDefaultAWSData(itemsAWS);
			setDefaultPortalUser(itemsPortalUser);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		

	},[reload]);
	
	useEffect(() => {

		const selectAWS= defaultAWSData.filter(x=>x.customer_no === data.customer_no);	
		const account_no = selectAWS?.[0]?.account_no ?? '';
		const support_name = selectAWS[0]?.aws_support?.filter((item) => item?.status === '有効').sort((a, b) => b?.contract_date - a?.contract_date)?.[0]?.support_name ?? '';

		setAWSData(selectAWS);

		const newData = { ...data };
		newData.account_no = account_no;
		newData.support_name = support_name;
		setData(newData);

		const selectPortalUser = defaultPortalUser.filter(x=>x.customer_no === data.customer_no);
		setPortalUser(selectPortalUser);
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[data?.customer_no, defaultAWSData, defaultPortalUser]);

	useEffect(() => {
	
		const support_name = defaultAWSData.filter(x=> x.account_no === data.account_no)?.[0]?.aws_support?.filter((item) => item?.status === '有効').sort((a, b) => b?.contract_date - a?.contract_date)?.[0]?.support_name ?? '';

		const newData = { ...data };
		newData.support_name = support_name;
		setData(newData);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[data?.account_no, defaultAWSData]);

	useEffect(() => {
	
		if(data.type === 'technical' || data.type === 'build'){
			setTechFlag((data.account_no && data.support_name) ? true : false);
		}
		else{
			setTechFlag(true);
		}

	},[data?.type, data?.account_no, data?.support_name]);

	useEffect(() => {
		if(case_id){
			window.location.href = '/support/' + case_id;
		}
	},[case_id]);

	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {

			console.log(data);
			console.log(event.target?.file1);

			Storage.configure({ AWSS3: { bucket: 'onocomm-system-support-upload', region: 'ap-northeast-1', level: 'public' } });

			//お客様の代わりにケースをオープンするためお客様のユーザ情報をセットする
			const account_no = data.account_no;
			const customer_no = data.customer_no;

			//投稿したユーザ名となる
			const user_name = data.user_name;

			//お問い合わせ内容
			const type = data.type;
			const subject = data.subject;
			const text_value = data.text_value;
			const update_user = data.update_user;
			const mail_cc = data.mail_cc;
			const file1Object = event.target?.file1?.files?.[0] ?? null;
			const file2Object = event.target?.file2?.files?.[0] ?? null;
			const file3Object = event.target?.file3?.files?.[0] ?? null;

			if(type === 'technical' && !techFlag){
				throw new Error('技術的なお問い合わせは契約しているサポートがないため、お問い合わせ出来ません');
			}
			
			if(!text_value){
				throw new Error('お問い合わせ内容が入力されていません');
			}

			if(!customer_no){
				throw new Error('お客様番号が取得できませんでした');
			}

			if(!user_name){
				throw new Error('ユーザー名が取得できませんでした');
			}

			let file1_original_name = null;
			let file1_name = null;
			if(file1Object){
				const file1Extension = file1Object.name.split('.').pop();
				const file1Size = file1Object.size;
				if(file1Size > 1024 * 1024 * 5){
					throw new Error('ファイルサイズが5MBを超えています');
				}
				file1_original_name = file1Object.name;
				file1_name = uuidv4() + '.' + file1Extension;
				await Storage.put(file1_name, file1Object);
			}
			let file2_original_name = null;
			let file2_name = null;
			if(file2Object){
				const file2Extension = file2Object.name.split('.').pop();
				const file2Size = file2Object.size;
				if(file2Size > 1024 * 1024 * 5){
					throw new Error('ファイルサイズが5MBを超えています');
				}
				file2_original_name = file2Object.name;
				file2_name = uuidv4() + '.' + file2Extension;
				await Storage.put(file2_name, file2Object);
			}
			let file3_original_name = null;
			let file3_name = null;
			if(file3Object){
				const file3Extension = file3Object.name.split('.').pop();
				const file3Size = file3Object.size;
				if(file3Size > 1024 * 1024 * 5){
					throw new Error('ファイルサイズが5MBを超えています');
				}
				file3_original_name = file3Object.name;
				file3_name = uuidv4() + '.' + file3Extension;
				await Storage.put(file3_name, file3Object);
			}

			const params = {
				user_name,
				account_no,
				customer_no,
				type,
				subject,
				text_value,
				file1_original_name,
				file1_name,
				file2_original_name,
				file2_name,
				file3_original_name,
				file3_name,
				update_user,
				mail_cc,
			};

			const result = await API.AWSSupport.Create(params);

			if(result.error){
				throw new Error(result.error);
			}

			const case_id = result?.ref?.data?.createAWSSupport?.case_id;

			if(!case_id){
				throw new Error('ケースIDが取得できませんでした');
			}

			setCaseId(case_id);

		}
		catch(err){
			setAlert([err.message]);
			setLock(false);
		}

	};
		
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が日付かどうか
		if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}

		//更新
		setData(newData);
	};

	const handleCustomerClick = (customer_no, customer_name) => {
		const newData = { ...data };
		newData.customer_no = customer_no;
		newData.customer_name = customer_name;
		setData(newData);
		setShowCustomer([false]);
	};

	const handleCustomerShow = () => {
		setShowCustomer([true]);
	};

	const handleClose = () => {
		setAlert([]);
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalCustomer data={customerData} show={showCustomer} lock={lock} onSelect={handleCustomerClick} />

				<Row>
					<Col sm={6}>
						<p><big>ケースの追加</big></p>
					</Col>
					<Col sm={6} className="text-right">
						<Button variant="dark" onClick={ handleReload }>
							<FaRedo />
						</Button>
					</Col>
				</Row>
				<hr/>
				<Form name="form" onSubmit={handleSubmit}>
					<Form.Group controlId="formTechnicalType">
						<Form.Label>種別</Form.Label>
						<Form.Control as="select" name="type" onChange={handleOnChange} value={data.type} required>
							<option value={'basic'}>一般</option>
							<option value={'technical'}>技術</option>
							<option value={'build'}>構築</option>
						</Form.Control>
					</Form.Group>
					<Form.Group controlId="formTechnicalSupport">
						<Form.Label>対象の顧客</Form.Label>
						<Button type="text" name="customer_no" variant="secondary" onClick={handleCustomerShow} onChange={handleOnChange} block required={true}>
							{data.customer_no ? (data.customer_no + '　' + data.customer_name) : '未選択'}
						</Button>
					</Form.Group>
					<Form.Group controlId="formTechnicalAccount">
						<Form.Label>対象の担当者</Form.Label>
						<Form.Control as="select" name="update_user" onChange={handleOnChange} value={data.update_user} required>
							{portalUser.map((item, index) => {
									return(
										<option key={index} value={item.user_id}>{item.user_name}（{item.email}）</option>
									);
								}
							)}
						</Form.Control>
					</Form.Group>
					<Form.Group controlId="formTechnicalAccount">
						<Form.Label>対象のAWSアカウント</Form.Label>
						<Form.Control as="select" name="account_no" onChange={handleOnChange} value={data.account_no} required>
							{awsData.map((item, index) => {
									return(
										<option key={index} value={item.account_no}>{item.account_name}（{item.account_no}）</option>
									);
								}
							)}
						</Form.Control>
					</Form.Group>
					<Form.Group controlId="formTechnicalSupport">
						<Form.Label>サポート</Form.Label>
						<Form.Control type="text" name="user_name" value={data.support_name ? data.support_name : ''} placeholder="サポート" readOnly={true} />
					</Form.Group>
					<Form.Group controlId="formTechnicalUser">
						<Form.Label>ユーザ名</Form.Label>
						<Form.Control as="select" name="user_name" onChange={handleOnChange} value={data.user_name} disabled={!techFlag} required>
							<option value={''}>---</option>
							<option value={'斧山　洋一'}>斧山　洋一</option>
							<option value={'滝口　泰之'}>滝口　泰之</option>
							<option value={'東方　昭洋'}>東方　昭洋</option>
						</Form.Control>
					</Form.Group>
					<Form.Group controlId="formTechnicalTitle">
						<Form.Label>題名</Form.Label>
						<Form.Control type="text" name="subject" placeholder="題名" onChange={handleOnChange} value={data.subject} disabled={!techFlag} required />
					</Form.Group>
					<Form.Group controlId="formTechnicalValue">
						<Form.Label>お問い合わせ内容</Form.Label>
						<Form.Control as="textarea" name="text_value" rows={10} placeholder="お問い合わせ内容" onChange={handleOnChange} value={data.text_value} disabled={!techFlag} required />
					</Form.Group>
					<Form.Group controlId="formTechnicalMailCC">
						<Form.Label>メールCC宛先</Form.Label>
						<Form.Control type="email" name="mail_cc" placeholder="メールCC宛先" onChange={handleOnChange} value={data.mail_cc} disabled={!techFlag} />
					</Form.Group>
					<Form.Group controlId="formTechnicalFile1">
						<Form.File name="file1" id="custom-file" label="ファイル1を添付" disabled={!techFlag} />
					</Form.Group>
					<Form.Group controlId="formTechnicalFile2">
						<Form.File name="file2" id="custom-file" label="ファイル2を添付" disabled={!techFlag} />
					</Form.Group>
					<Form.Group controlId="formTechnicalFile3">
						<Form.File name="file3" id="custom-file" label="ファイル3を添付" disabled={!techFlag} />
					</Form.Group>
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock || !techFlag} >OK</Button>
						</Col>
					</Row>

					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>



			</Container>
		);
	}
};


export default withRouter(Default);
